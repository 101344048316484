// **  Initial State
const initialState = {
    minFlowDetailsDate: undefined,
    maxFlowDetailsDate: undefined,
    eventXAmbXVisitors: undefined
}

const flowDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FLOW_DETAILS_DATES':
            return {
                ...state,
                minFlowDetailsDate: action.data.minFlowDetailsDate,
                maxFlowDetailsDate: action.data.maxFlowDetailsDate
            }
        case 'FLOW_DETAILS_EVENTS_X_AMBIENTS_X_VISITORS':
            return {
                ...state,
                eventXAmbXVisitors: action.data.eventXAmbXVisitors
            }
        default:
            return state
    }
}

export default flowDetailsReducer