// **  Initial State
const initialState = {
    instaPage: { value: undefined, label: undefined },
    instaPageStartDateAux: undefined,
    instaPageEndDateAux: undefined,
    instaPageInsightsStartDate: undefined,
    instaPageInsightsEndDate: undefined
}

const instaPagesMetaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INSTA_PAGE_FILTER':
            return {
                ...state,
                instaPage: action.data.instaPage
            }
        case 'INSTA_PAGE_DATES_AUX':
            return {
                ...state,
                instaPageStartDateAux: action.data.instaPageStartDateAux,
                instaPageEndDateAux: action.data.instaPageEndDateAux
            }
        case 'INSTA_PAGE_PERIODS_FILTER':
            return {
                ...state,
                instaPageInsightsStartDate: action.data.instaPageInsightsStartDate,
                instaPageInsightsEndDate: action.data.instaPageInsightsEndDate
            }
        default:
            return state
    }
}

export default instaPagesMetaReducer
