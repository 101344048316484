// **  Initial State
const initialState = {
  userData: {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN": { return { ...state, userData: action.data } }
    case "LOGOUT": { return { ...state, userData: {} } }
    case "CHANGE_STATUS": { return { ...state, userData: { ...state.userData, loginStatus: action.data } } }
    case "REVOKE_STATUS": { return { ...state, userData: { loginStatus: action.data } } }
    default: { return state }
  }
}

export default authReducer
