// **  Initial State
const initialState = {
    chartTypeOptions: [{ value: undefined, label: '' }],
    chartType: { value: undefined, label: '' }
}

const adCampaignsMetaMetricReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FB_AD_ACCOUNT_SET_METRIC_CHOICES':
            return { ...state, chartTypeOptions: action.data.chartTypeOptions }
        case 'FB_AD_ACCOUNT_SET_SET_METRIC':
            return { ...state, chartType: action.data.chartType }
        default:
            return state
    }
}

export default adCampaignsMetaMetricReducer
