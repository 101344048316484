// **  Initial State
const initialState = {
    chartTypeOptions: [{ value: undefined, label: '' }],
    chartType: { value: undefined, label: '' }
}

const instaPagesMetaMetricReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INSTA_PAGE_SET_METRIC_CHOICES':
            return { ...state, chartTypeOptions: action.data.chartTypeOptions }
        case 'INSTA_PAGE_SET_METRIC':
            return { ...state, chartType: action.data.chartType }
        default:
            return state
    }
}

export default instaPagesMetaMetricReducer
