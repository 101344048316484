// **  Initial State
const initialState = {
    phygitalFBAdCampaign: { value: undefined, label: undefined, item_id: undefined, start_time: undefined, end_time: undefined, daysDiff: undefined },
    phygitalIDAmbients: [{ value: undefined, label: undefined }],
}

const PhygitalAdCampaignsMetaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PHYGITAL_AD_CAMPAIGN_META_FILTER':
            return {
                ...state,
                phygitalFBAdCampaign: action.data.phygitalFBAdCampaign
            }
        case 'PHYGITAL_ID_AMBIENTS_META_FILTER':
            return {
                ...state,
                phygitalIDAmbients: action.data.phygitalIDAmbients
            }
        default:
            return state
    }
}

export default PhygitalAdCampaignsMetaReducer
