// **  Initial State
const initialState = {
    mainFilterDisplayType: 1
}

const mainFilterDisplayTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MAIN_FILTER_DISPLAY_TYPE':
            // 1 = Closed / 2 = Open
            return { ...state, mainFilterDisplayType: action.data.mainFilterDisplayType }
        default:
            return state
    }
}

export default mainFilterDisplayTypeReducer
