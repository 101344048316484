// **  Initial State
const initialState = {
    profileChartType: 1
}

const profileChartTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PROFILE_CHART_TYPE':
            return { ...state, profileChartType: action.data.profileChartType }
        default:
            return state
    }
}

export default profileChartTypeReducer
