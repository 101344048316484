// **  Initial State
const initialState = {
    customers: [{ value: 0, label: 'Todos' }],
    states: [{ value: 0, label: 'Todos' }],
    cities: [{ value: 0, label: 'Todos' }],
    ambients: [{ value: 0, label: 'Todos' }],
    chartTime: 0,
    days: 0,
    startDate: '',
    endDate: '',
    daysDiff: 0
}

const chartsFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CUSTOMER_FILTER':
            return {
                ...state,
                customers: action.data.customers
            }
        case 'AMBIENT_STATE_FILTER':
            return {
                ...state,
                states: action.data.states
            }
        case 'AMBIENT_CITY_FILTER':
            return {
                ...state,
                cities: action.data.cities
            }
        case 'AMBIENT_FILTER':
            return {
                ...state,
                ambients: action.data.ambients
            }
        case 'DAYS_FILTER':
            return {
                ...state,
                chartTime: action.data.chartTime,
                days: action.data.days,
                startDate: '',
                endDate: '',
                daysDiff: 0
            }
        case 'PERIODS_FILTER':
            return {
                ...state,
                chartTime: action.data.chartTime,
                days: 0,
                startDate: action.data.startDate,
                endDate: action.data.endDate,
                daysDiff: action.data.daysDiff
            }
        default:
            return state
    }
}

export default chartsFilterReducer
