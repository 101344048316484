// **  Initial State
const initialState = {
    fbPage: { value: undefined, label: undefined },
    fbPageStartDateAux: undefined,
    fbPageEndDateAux: undefined,
    fbPageInsightsStartDate: undefined,
    fbPageInsightsEndDate: undefined
}

const fbPagesMetaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FB_PAGE_FILTER':
            return {
                ...state,
                fbPage: action.data.fbPage
            }
        case 'FB_PAGE_DATES_AUX':
            return {
                ...state,
                fbPageStartDateAux: action.data.fbPageStartDateAux,
                fbPageEndDateAux: action.data.fbPageEndDateAux
            }
        case 'FB_PAGE_PERIODS_FILTER':
            return {
                ...state,
                fbPageInsightsStartDate: action.data.fbPageInsightsStartDate,
                fbPageInsightsEndDate: action.data.fbPageInsightsEndDate
            }
        default:
            return state
    }
}
export default fbPagesMetaReducer
