// ** Initial State
const initialState = {
  events: [],
  selectedEvent: {},
  selectedCalendars: ['Campanhas', 'Comemorações', 'Feriados']
}

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_EVENTS':
      return { ...state, events: action.events }
    case 'ADD_EVENT':
      return { ...state }
    case 'REMOVE_EVENT':
      return { ...state }
    case 'UPDATE_EVENT':
      return { ...state }
    case 'UPDATE_FILTERS':

      // ** Updates Filters based on action filter
      const selectedCalendars = Array.from(state.selectedCalendars) // Deep Copy so that useEffect in other Pages work - Immutable Data
      const filterIndex = selectedCalendars.findIndex(i => i === action.filter)

      if (selectedCalendars.includes(action.filter)) {
        selectedCalendars.splice(filterIndex, 1)
      } else {
        selectedCalendars.push(action.filter)
      }

      return { ...state, selectedCalendars }
    case 'UPDATE_ALL_FILTERS':
      // ** Updates All Filters based on action value
      const value = action.value
      let selected = []
      if (value === true) {
        selected = ['Campanhas', 'Comemorações', 'Feriados']
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: selected }
    case 'SELECT_EVENT':
      {
        return { ...state, selectedEvent: action.event }
      }
    default:
      return state
  }
}

export default calendarReducer
