// **  Initial State
const initialState = {
    fbAdAccount: { value: undefined, label: undefined, access_token: undefined },
    fbAdCampaignName: undefined,
    fbAdCampaignType: { value: undefined, label: 'Todos' },
    fbAdCampaignCompleted: { value: undefined, label: 'Ambos' },
    fbAdCampaignStartDate: undefined,
    fbAdCampaignInsightsStartDate: undefined,
    fbAdCampaignInsightsEndDate: undefined
}

const adCampaignsMetaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FB_AD_ACCOUNT_FILTER':
            return {
                ...state,
                fbAdAccount: action.data.fbAdAccount
            }
        case 'FB_AD_CAMPAIGN_NAME_FILTER':
            return {
                ...state,
                fbAdCampaignName: action.data.fbAdCampaignName
            }
        case 'FB_AD_CAMPAIGN_TYPE_FILTER':
            return {
                ...state,
                fbAdCampaignType: action.data.fbAdCampaignType
            }
        case 'FB_AD_CAMPAIGN_COMPLETED_FILTER':
            return {
                ...state,
                fbAdCampaignCompleted: action.data.fbAdCampaignCompleted
            }
        case 'FB_AD_CAMPAIGN_START_DATE_FILTER':
            return {
                ...state,
                fbAdCampaignStartDate: action.data.fbAdCampaignStartDate
            }
        case 'FB_AD_CAMPAIGN_PERIODS_FILTER':
            return {
                ...state,
                fbAdCampaignInsightsStartDate: action.data.fbAdCampaignInsightsStartDate,
                fbAdCampaignInsightsEndDate: action.data.fbAdCampaignInsightsEndDate
            }
        default:
            return state
    }
}

export default adCampaignsMetaReducer
