// **  Initial State
const initialState = {
    ID_Integrations: undefined,
    API_Integrations: undefined,
    API_userAccessToken: undefined,
}

const metaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FB_LOAD_DATA_API':
            return {
                ...state,
                API_Integrations: action.data.integrations,
                API_userAccessToken: action.data.userAccessToken,
            }
        case 'FB_LOAD_DATA_INTRADATA':
            // Loading intradata data and excluding other data.
            // Will be used on first load + refresh after updates.
            return {
                ...state,
                ID_Integrations: action.data.integrations,
                API_Integrations: undefined,
                API_userAccessToken: undefined
            }
        default:
            return state
    }
}

export default metaReducer
