// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import layout from './layout'

import mainFilterDisplay from './main-filter-display'
import chartsFilter from './charts-filter'
import profileChartConfig from './chart-profile'
import flowDetails from './flow-details'
import meta from './meta'
import adCampaignsMetaFilter from './ad-campaigns-meta-filter'
import adCampaignsMetaMetricReducer from './ad-campaigns-meta-metric'
import fbPagesMetaFilter from './fb-pages-meta-filter'
import instaPagesMetaFilter from './insta-pages-meta-filter'
import instaPagesMetaMetricReducer from './insta-pages-meta-metric'
import phygitalAdCampaignsMeta from './phygital-ad-campaigns-meta'
import calendar from '@src/views/events/calendar/store/reducer'

const rootReducer = combineReducers({
  auth,
  layout,
  mainFilterDisplay,
  chartsFilter,
  profileChartConfig,
  flowDetails,
  meta,
  adCampaignsMetaFilter,
  adCampaignsMetaMetricReducer,
  fbPagesMetaFilter,
  instaPagesMetaFilter,
  instaPagesMetaMetricReducer,
  phygitalAdCampaignsMeta,
  calendar
})

export default rootReducer
